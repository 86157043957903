<script>
  // Import external dependencies.
  import { translate } from "i18n"; //eslint-disable-line import/no-unresolved
  import modalpowers from "../helpers/action-modal";

  // Props
  let isTouch =
    "ontouchstart" in window ||
    (window.DocumentTouch && document instanceof window.DocumentTouch) ||
    navigator.maxTouchPoints > 0 ||
    window.navigator.msMaxTouchPoints > 0;
  const control_bar_help = [
    [
      "multiviewer.tutorial.tool_selector",
      "multiviewer.tutorial.tool_selector_desc",
    ],
    [
      "multiviewer.tutorial.show_details",
      "multiviewer.tutorial.show_details_desc",
    ],
    ["multiviewer.tutorial.add_series", "multiviewer.tutorial.add_series_desc"],
    ["multiviewer.tutorial.share", "multiviewer.tutorial.share_desc"],
    [
      "multiviewer.tutorial.open_tutorial",
      "multiviewer.tutorial.open_tutorial_desc",
    ],
    [
      "multiviewer.tutorial.keyboard_shortcuts",
      "multiviewer.tutorial.keyboard_shortcuts_desc",
    ],
    [
      "multiviewer.tutorial.dicom_details",
      "multiviewer.tutorial.dicom_details_desc",
    ],
    [
      "multiviewer.tutorial.go_to_study",
      "multiviewer.tutorial.go_to_study_desc",
    ],
    [
      "multiviewer.tutorial.toggle_interpolation",
      "multiviewer.tutorial.toggle_interpolation_desc",
    ],
    ["multiviewer.tutorial.view_mode", "multiviewer.tutorial.view_mode_desc"],
    [
      "multiviewer.tutorial.panel_options",
      "multiviewer.tutorial.panel_options_desc",
    ],
    [
      "multiviewer.tutorial.expand_panel",
      "multiviewer.tutorial.expand_panel_desc",
    ],
    [
      "multiviewer.tutorial.close_panel",
      "multiviewer.tutorial.close_panel_desc",
    ],
    [
      "multiviewer.tutorial.link_rotation",
      "multiviewer.tutorial.link_rotation_desc",
    ],
    ["multiviewer.tutorial.link_pan", "multiviewer.tutorial.link_pan_desc"],
    ["multiviewer.tutorial.link_zoom", "multiviewer.tutorial.link_zoom_desc"],
    ["multiviewer.tutorial.auto_zoom", "multiviewer.tutorial.auto_zoom_desc"],
    [
      "multiviewer.tutorial.zoom_control",
      "multiviewer.tutorial.zoom_control_desc",
    ],
    ["multiviewer.tutorial.link_slice", "multiviewer.tutorial.link_slice_desc"],
    [
      "multiviewer.tutorial.slice_control",
      "multiviewer.tutorial.slice_control_desc",
    ],
    [
      "multiviewer.tutorial.auto_window",
      "multiviewer.tutorial.auto_window_desc",
    ],
    [
      "multiviewer.tutorial.window_control",
      "multiviewer.tutorial.window_control_desc",
    ],
    ["multiviewer.tutorial.auto_level", "multiviewer.tutorial.auto_level_desc"],
    [
      "multiviewer.tutorial.level_control",
      "multiviewer.tutorial.level_control_desc",
    ],
    ["multiviewer.tutorial.mini_cube", "multiviewer.tutorial.mini_cube_desc"],
  ];
</script>

<div
  class="modal fade"
  id="MultiviewerTutorialModal"
  tabindex="-1"
  aria-labelledby="MultiviewerTutorialModalLabel"
  aria-hidden="true"
  use:modalpowers
>
  <div class="modal-dialog modal-xl modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title">Multiviewer Tutorial</h1>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <!-- Tabs
      <nav class="nav secondary-nav border-bottom">
        <span
          class="nav-link unlink pt-4"
          class:active={isTouch}
          on:click={() => (isTouch = true)}
        >
          {translate("touch")}
        </span>
        <span
          class="nav-link unlink pt-4"
          class:active={!isTouch}
          on:click={() => (isTouch = false)}
        >
          {translate("mouse")}
        </span>
      </nav> -->
      <div class="modal-body">
        <!-- Buttons & Controls -->
        <div class="mt-4">
          <h2 class="mb-4">
            {translate("multiviewer.tutorial.buttons_and_controls")}
          </h2>
          <img
            class="mw-100 mx-auto d-block mb-4 rounded"
            src="images/multiviewer_annotated.jpg"
            alt={translate("multiviewer.tutorial.control_bar_img_alt")}
          />
          <div class="container-fluid px-0">
            <ol class="row">
              {#each control_bar_help as [tk, dk] (tk)}
                <li class="col-sm-6 col-xl-4 my-2 pr-xl-5 pl-1">
                  {translate(tk)}
                  <br />
                  <small class="text-muted">{translate(dk)}</small>
                </li>
              {/each}
            </ol>
          </div>
        </div>

        <!-- In-Panel Control ( Touch & Mouse ) -->
        <div class="mb-3 mt-4 mt-lg-5">
          <section>
            <div class="mb-4">
              <h2 class="mb-0 mr-4 d-inline-block">
                {translate("multiviewer.tutorial.in_panel")}
              </h2>
              <div class="d-inline-block">
                <div class="custom-control custom-switch my-1">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="showTouchControls"
                    bind:checked={isTouch}
                  />
                  <label class="custom-control-label" for="showTouchControls"
                    >Touch Controls</label
                  >
                </div>
              </div>
            </div>

            <!-- Left Click & Drag -->
            <div class="row align-items-center my-4">
              <div class="col-auto">
                {#if isTouch}
                  <img
                    class="fingers-img"
                    src="images/gestures/one-finger-146.png"
                    srcset="images/gestures/one-finger-73.png,
                        images/gestures/one-finger-146.png 2x"
                    alt={translate("multiviewer.tutorial.main_click_img_alt")}
                  />
                {:else}
                  <svg
                    width="80px"
                    version="1.1"
                    viewBox="0 0 176.58 200.12"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g
                      transform="translate(-15.868 -26.638)"
                      fill="currentColor"
                    >
                      <path
                        d="m90.853 184.43c-4e-6 23.38 18.953 42.333 42.333
                            42.333h16.933c23.38 0 42.333-18.953
                            42.333-42.333v-33.867h-101.6zm46.567-93.133h-4.2333c-23.38-2e-6
                            -42.333 18.953-42.333 42.333v8.4667h46.567zm12.7
                            0h-4.2333v60.2l46.567-0.222v-17.645c0-23.38-18.953-42.333-42.333-42.333z"
                        stroke-width=".26458"
                      />
                      <path
                        d="m74.49 97.506-13.182 13.182c-1.5601 1.5601-4.0892
                            1.5601-5.6493
                            0l-13.182-13.182c-2.5165-2.5165-0.73418-6.8194
                            2.8248-6.8193h8.5157l-1.76e-4 -16.778h-16.778v8.5157c0
                            3.5589-4.3028 5.3412-6.8194
                            2.8247l-13.182-13.182c-1.5601-1.5601-1.5601-4.0894
                            0-5.6493l13.182-13.182c2.5165-2.5165 6.8194-0.7342
                            6.8194 2.8247v8.5268h16.778v-16.778h-8.527c-3.5589
                            0-5.3412-4.3028-2.8247-6.8194l13.182-13.182c1.5601-1.5601
                            4.0892-1.5601 5.6493 0l13.182 13.182c2.5165 2.5165
                            0.7342 6.8194-2.8247
                            6.8194h-8.5157v16.778h16.778v-8.5157c0-3.5589
                            4.3028-5.3412 6.8193-2.8247l13.182 13.182c1.5601 1.5601
                            1.5601 4.0894 0 5.6493l-13.182 13.182c-2.5165
                            2.5165-6.8194
                            0.73418-6.8193-2.8247v-8.5268h-16.778v16.778h8.527c3.5589
                            0 5.3412 4.3028 2.8247 6.8194z"
                        stroke-width=".16644"
                      />
                    </g>
                  </svg>
                {/if}
              </div>
              <div class="col">
                <span>
                  {translate(
                    isTouch
                      ? "multiviewer.tutorial.touch_one"
                      : "multiviewer.tutorial.left_drag"
                  )}
                </span>
                <br />
                <span class="text-muted">
                  {translate("multiviewer.tutorial.main_click_info")}
                </span>
                <br />
                <span class="text-muted small mt-2 d-inline-block">
                  <em>{translate("multiviewer.tutorial.modifiers")}</em>
                  :
                  <br />
                  <kbd>ctrl</kbd>
                  - {translate("multiviewer.tutorial.left_click_mod_ctrl")}
                </span>
              </div>
            </div>

            <!-- Right Click & Drag -->
            <div class="row align-items-center my-4">
              <div class="col-auto">
                {#if isTouch}
                  <img
                    class="fingers-img"
                    src="images/gestures/two-fingers-146.png"
                    srcset="images/gestures/two-fingers-73.png,
                        images/gestures/two-fingers-146.png 2x"
                    alt={translate("multiviewer.tutorial.alt_click_img_alt")}
                  />
                {:else}
                  <svg
                    width="80px"
                    version="1.1"
                    viewBox="0 0 176.58 200.12"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g
                      transform="translate(-15.868 -26.638)"
                      fill="currentColor"
                    >
                      <path
                        d="m90.853 184.43c-4e-6 23.38 18.953 42.333 42.333
                            42.333h16.933c23.38 0 42.333-18.953
                            42.333-42.333v-33.867h-101.6zm46.567-93.133h-4.2333c-23.38-2e-6
                            -42.333 18.953-42.333 42.333v18.013h46.567zm12.7
                            0h-4.2333v50.8h46.567v-8.4668c0-23.38-18.953-42.333-42.333-42.333z"
                        stroke-width=".26458"
                      />
                      <path
                        d="m74.49 97.506-13.182 13.182c-1.5601 1.5601-4.0892
                            1.5601-5.6493
                            0l-13.182-13.182c-2.5165-2.5165-0.73418-6.8194
                            2.8248-6.8193h8.5157l-1.76e-4 -16.778h-16.778v8.5157c0
                            3.5589-4.3028 5.3412-6.8194
                            2.8247l-13.182-13.182c-1.5601-1.5601-1.5601-4.0894
                            0-5.6493l13.182-13.182c2.5165-2.5165 6.8194-0.7342
                            6.8194 2.8247v8.5268h16.778v-16.778h-8.527c-3.5589
                            0-5.3412-4.3028-2.8247-6.8194l13.182-13.182c1.5601-1.5601
                            4.0892-1.5601 5.6493 0l13.182 13.182c2.5165 2.5165
                            0.7342 6.8194-2.8247
                            6.8194h-8.5157v16.778h16.778v-8.5157c0-3.5589
                            4.3028-5.3412 6.8193-2.8247l13.182 13.182c1.5601 1.5601
                            1.5601 4.0894 0 5.6493l-13.182 13.182c-2.5165
                            2.5165-6.8194
                            0.73418-6.8193-2.8247v-8.5268h-16.778v16.778h8.527c3.5589
                            0 5.3412 4.3028 2.8247 6.8194z"
                        stroke-width=".16644"
                      />
                    </g>
                  </svg>
                {/if}
              </div>
              <div class="col">
                <span>
                  {translate(
                    isTouch
                      ? "multiviewer.tutorial.touch_two"
                      : "multiviewer.tutorial.right_drag"
                  )}
                </span>
                <br />
                <span class="text-muted">
                  {translate("multiviewer.tutorial.alt_click_info")}
                </span>
                <br />
                <span class="text-muted small mt-2 d-inline-block">
                  <em>{translate("multiviewer.tutorial.modifiers")}</em>
                  :
                  <br />
                  <kbd>ctrl</kbd>
                  - {translate("multiviewer.tutorial.right_click_mod_ctrl")}
                </span>
              </div>
            </div>

            <!-- Middle Click & Drag -->
            {#if !isTouch}
              <div class="row align-items-center my-4">
                <div class="col-auto">
                  <svg
                    width="80px"
                    version="1.1"
                    viewBox="0 0 176.58 200.12"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g
                      transform="translate(-15.868 -26.638)"
                      fill="currentColor"
                    >
                      <path
                        d="m90.853 184.43c-4e-6 23.38 18.953 42.333 42.333
                            42.333h16.933c23.38 0 42.333-18.953
                            42.333-42.333v-33.867h-101.6zm44.015-93.133h-1.6818c-23.38
                            0-42.333 18.953-42.333 42.333v8.4668h44.015zm15.252
                            0h-2.7516v50.8h45.085v-8.4668c0-23.38-18.953-42.333-42.333-42.333z"
                        fill="currentColor"
                        stroke-width=".26458"
                      />
                      <path
                        d="m74.49 97.506-13.182 13.182c-1.5601 1.5601-4.0892
                            1.5601-5.6493
                            0l-13.182-13.182c-2.5165-2.5165-0.73418-6.8194
                            2.8248-6.8193h8.5157l-1.76e-4 -16.778h-16.778v8.5157c0
                            3.5589-4.3028 5.3412-6.8194
                            2.8247l-13.182-13.182c-1.5601-1.5601-1.5601-4.0894
                            0-5.6493l13.182-13.182c2.5165-2.5165 6.8194-0.7342
                            6.8194 2.8247v8.5268h16.778v-16.778h-8.527c-3.5589
                            0-5.3412-4.3028-2.8247-6.8194l13.182-13.182c1.5601-1.5601
                            4.0892-1.5601 5.6493 0l13.182 13.182c2.5165 2.5165
                            0.7342 6.8194-2.8247
                            6.8194h-8.5157v16.778h16.778v-8.5157c0-3.5589
                            4.3028-5.3412 6.8193-2.8247l13.182 13.182c1.5601 1.5601
                            1.5601 4.0894 0 5.6493l-13.182 13.182c-2.5165
                            2.5165-6.8194
                            0.73418-6.8193-2.8247v-8.5268h-16.778v16.778h8.527c3.5589
                            0 5.3412 4.3028 2.8247 6.8194z"
                        fill="currentColor"
                        stroke-width=".16644"
                      />
                      <path
                        d="m138.43 91.292h6.0125v50.8h-6.0125z"
                        stroke-width=".2313"
                      />
                    </g>
                  </svg>
                </div>
                <div class="col">
                  <span>{translate("multiviewer.tutorial.middle_drag")}</span>
                  <br />
                  <span class="text-muted">
                    {translate("multiviewer.tutorial.middle_drag_info")}
                  </span>
                </div>
              </div>
            {/if}
          </section>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          Close
        </button>
      </div>
    </div>
  </div>
</div>

<style type="text/scss">/* Sass Variables */
@font-face {
  font-family: "Neris";
  src: url("/fonts/Neris-Regular-webfont.woff") format("woff"), url("/fonts/Neris-Regular-webfont.woff2") format("woff2");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: "Neris";
  src: url("/fonts/Neris-Italic-webfont.woff") format("woff"), url("/fonts/Neris-Italic-webfont.woff2") format("woff2");
  font-weight: 400;
  font-style: italic; }

@font-face {
  font-family: "Neris";
  src: url("/fonts/Neris-Light-webfont.woff") format("woff"), url("/fonts/Neris-Light-webfont.woff2") format("woff2");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: "Neris";
  src: url("/fonts/Neris-LightItalic-webfont.woff") format("woff"), url("/fonts/Neris-LightItalic-webfont.woff2") format("woff2");
  font-weight: 300;
  font-style: italic; }

@font-face {
  font-family: "Neris";
  src: url("/fonts/Neris-Black-webfont.woff") format("woff"), url("/fonts/Neris-Black-webfont.woff2") format("woff2");
  font-weight: 900;
  font-style: normal; }

@font-face {
  font-family: "Neris";
  src: url("/fonts/Neris-BlackItalic-webfont.woff") format("woff"), url("/fonts/Neris-BlackItalic-webfont.woff2") format("woff2");
  font-weight: 900;
  font-style: italic; }

.tutorial-modal :global(.modal-content) {
  border-top-color: #65C2FF; }

.fingers-img {
  width: 73px;
  height: 98px; }

.secondary-nav {
  z-index: 1; }
  .secondary-nav .nav-link {
    border-bottom: 1px solid transparent;
    transition: border-bottom-color 200ms ease-out;
    transform: translateY(1px); }
    .secondary-nav .nav-link.active {
      border-bottom-color: #65C2FF; }

/*# sourceMappingURL=x.map */</style>
