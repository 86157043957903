<svelte:options immutable={true} />

<script context="module">
  export const reflectedProps = {
    viewMode: "mod",
    isInterpolated: "interp",
    scrollX: "sx",
    scrollY: "sy",
    scrollZ: "sz",
    rotation: "rot",
    brightness: "bri",
    contrast: "con",
    zoom: "zm",
    panX: "px",
    panY: "py",
    clipStr: "cli",
    colorMap2D: "cmap2d",
    colorMapMPR: "cmapmpr",
    colorMap3D: "cmap3d",
  };
</script>

<script>
  // Import external dependencies.
  import { onDestroy, createEventDispatcher, tick } from "svelte";
  import jQuery from "jquery";
  import VolumeViewer from "volume-viewer"; //eslint-disable-line import/no-unresolved
  import { translate, formatDate } from "i18n"; //eslint-disable-line import/no-unresolved
  import noop from "lodash-es/noop";
  import makeUUID from "../helpers/uuid";
  import { MSS } from "../helpers/formatters";
  import {
    active_tool_idx,
    show_metadata,
    colorMaps,
    defaultColorMap2D,
    defaultColorMapMPR,
    defaultColorMap3D,
  } from "./stores";
  import ViewerControls from "./viewer-controls.svelte";

  // Destructure some tools out of the compiled volume-viewer lib.
  const {
    Tables: { blackBgTable, bloodLuminosityTable, bloodGradientTable },
  } = VolumeViewer;

  // Create component internals
  const uuid = makeUUID();
  const dispatch = createEventDispatcher();

  // Component Props
  // - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - - |
  export let viewMode;
  export let isInterpolated;
  export let scrollX = false;
  export let scrollY = false;
  export let scrollZ = false;
  export let globalScrollX = false;
  export let globalScrollY = false;
  export let globalScrollZ = false;
  export let globalRotation = null;
  export let rotation = false;
  export let brightness = false;
  export let contrast = false;
  export let zoom = false;
  export let panX = false;
  export let panY = false;
  export let globalPanX = 0;
  export let globalPanY = 0;
  export let globalZoom = false;
  export let clipStr = "";
  export let renderTrigger = 0;
  export let colorMap2D = "";
  export let colorMapMPR = "";
  export let colorMap3D = "";
  export let series_promise; // This will resolve with a Daikon Image.
  let series;
  $: series_promise.then((the_series) => (series = the_series));

  // URL Reflection
  // - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - - |
  $: dispatch("reflect", { name: reflectedProps.viewMode, value: viewMode });
  $: dispatch("reflect", { name: reflectedProps.scrollX, value: scrollX });
  $: dispatch("reflect", { name: reflectedProps.scrollY, value: scrollY });
  $: dispatch("reflect", { name: reflectedProps.scrollZ, value: scrollZ });
  $: dispatch("reflect", { name: reflectedProps.rotation, value: rotation });
  $: dispatch("reflect", { name: reflectedProps.zoom, value: zoom });
  $: dispatch("reflect", { name: reflectedProps.contrast, value: contrast });
  $: dispatch("reflect", { name: reflectedProps.panX, value: panX });
  $: dispatch("reflect", { name: reflectedProps.panY, value: panY });
  $: dispatch("reflect", {
    name: reflectedProps.brightness,
    value: brightness,
  });
  $: dispatch("reflect", {
    name: reflectedProps.isInterpolated,
    value: isInterpolated.toString(),
  });
  $: dispatch("reflect", {
    name: reflectedProps.clipStr,
    value: clipNormal ? { ...clipNormal, clipOffset } : false,
  });
  $: dispatch("reflect", {
    name: reflectedProps.colorMap2D,
    value: colorMap2D,
  });
  $: dispatch("reflect", {
    name: reflectedProps.colorMapMPR,
    value: colorMapMPR,
  });
  $: dispatch("reflect", {
    name: reflectedProps.colorMap3D,
    value: colorMap3D,
  });

  // Integrate with Volume Viewer
  // - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - - |
  let autoBrightness;
  let autoContrast;
  let canvasEl;
  let viewer;
  $: if (series && canvasEl) renderVolume();
  function renderVolume() {
    if (viewer && viewer.destroy) viewer.destroy();
    const _viewer = new VolumeViewer(jQuery(canvasEl));
    _viewer.setVolumeRaw(series.volume);
    _viewer.setLuminosityTable(bloodLuminosityTable, 0);
    _viewer.setGradientTable(bloodGradientTable, 0);
    _viewer.enableLuminosityLighting(false);
    _viewer.setLuminosityAlpha(0.35);
    _viewer.setInputMode(VolumeViewer.INPUT_MODE_ROCKER);
    _viewer.enableAutoAdjustFramerate(true);
    _viewer.enableAutoCanvasResize(false);
    _viewer.enableClipping(false);
    _viewer.setZoomRangeMinMax(0.0625, 4);
    _viewer.addVolumeProcessedCallback(function OnVolumeProcessed() {
      // Cleanup incase we re-process the volume.
      _viewer.clearActionCallbacks();
      _viewer.clearVolumeProcessedCallbacks();

      // Set it up for slices.
      _viewer.enableMiniCube();
      _viewer.setInputWindow();

      // Interpolation
      _viewer.enableInterpolation(isInterpolated);
      if (!isInterpolated) _viewer.enablePlaneSnapToVoxel(true);

      _viewer.setFaceAxial();
      _viewer.enableAutoRotation(false);
      _viewer.setPlaneOffsetXYZ(0.0, 0.0, 0.0);
      _viewer.setDefaultFilters(null, null);
      _viewer.setResamplingMultipliers(1.0, 1.0, 1.0);
      _viewer.setBackgroundColorRGBA(0.0, 0.0, 0.0, 1.0);

      // Gradient & Luminosity
      _viewer.setGradientRange(_viewer.getAutoGradientRange(0), 0);
      _viewer.setLuminosityRange(_viewer.getAutoLuminosityRange(), 0);

      // Listen for on-canvas changes like scrolling, luminosity, zoom, pan etc.
      _viewer.addActionCallback(({ actionName, isUserAction }) => {
        if (!isUserAction) return false;

        switch (actionName) {
          // Scroll Handling
          case VolumeViewer.ACTION_PLANE: {
            const { px, py, pz } = _viewer.getPlaneOffset();
            if (scrollZ === false) globalScrollZ = pz;
            else scrollZ = pz;

            if (scrollY === false) globalScrollY = py;
            else scrollY = py;

            if (scrollX === false) globalScrollX = px;
            else scrollX = px;
            break;
          }
          // Rotation Handling
          case VolumeViewer.ACTION_ROTATE: {
            const matrix = _viewer.getRotationMatrix();
            if (!rotation) globalRotation = matrix;
            else rotation = matrix;
            break;
          }
          // Luminosity Handling
          case VolumeViewer.ACTION_THRESHOLDS: {
            const luminosity = _viewer.getLuminosityBrightnessAndContrast();
            brightness = luminosity.brightness;
            contrast = luminosity.contrast;
            break;
          }
          // Zoom Handling
          case VolumeViewer.ACTION_ZOOM: {
            const _zoom = _viewer.getZoom();
            if (zoom === false) globalZoom = _zoom;
            else zoom = _zoom;
            break;
          }
          // Pan Handling
          case VolumeViewer.ACTION_PAN: {
            const { x, y } = _viewer.getScreenPan();

            if (panX === false) globalPanX = x;
            else panX = x;

            if (panY === false) globalPanY = y;
            else panY = y;
            break;
          }
          // Clip Handling
          case VolumeViewer.ACTION_CLIP: {
            clipNormal = _viewer.getClipNormal();
            clipOffset = _viewer.getClipOffset();
            break;
          }
          default:
            break;
        }
      });

      // Read the default "auto" values for brightness and contrast.
      const bAndC = _viewer.getLuminosityBrightnessAndContrast();
      autoBrightness = bAndC.brightness;
      autoContrast = bAndC.contrast;

      // Inject the bootstrap'd viewer into Svelte's view.
      viewer = _viewer;
    });
  }

  // Parse Clipping String into actual values.
  let clipNormal = false;
  let clipOffset = false;
  $: parseClippingFromUrlString(clipStr);
  function parseClippingFromUrlString() {
    if (!clipStr) {
      clipNormal = clipOffset = false;
    } else {
      clipNormal = JSON.parse(clipStr);
      clipOffset = clipNormal.clipOffset;
    }
  }

  // Handle clipping from the controls
  function handleReclip() {
    if (!viewer || viewMode !== "3D") return false;

    clipOffset = 0.2;
    if (!clipNormal) {
      // Turn on Clipping
      viewer.setClipNormalFromRotation();
      clipNormal = viewer.getClipNormal();
    } else {
      // Update clipping...
      viewer.setClipNormalFromRotation();
      const { x, y, z } = clipNormal;
      clipNormal = viewer.getClipNormal();

      // If it hasn't changed, turn off clipping.
      if (x === clipNormal.x && y === clipNormal.y && z === clipNormal.z) {
        clipNormal = clipOffset = false;
      }
    }
  }

  // Update Volume Viewer when Props change (via human input or URL)
  // -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --|

  // view mode - 2D, 3D, or MPR
  $: viewSettingsReactor(viewer, viewMode);
  function viewSettingsReactor() {
    if (viewer) {
      switch (viewMode) {
        case "2D": {
          viewer.show2DView(isInterpolated);
          viewer.setRotationMatrix(rotation || globalRotation);
          viewer.enablePlaneInteriorClamping(true);
          viewer.enableGradients(false);
          viewer.setBackgroundColorRGBA(0.0, 0.0, 0.0, 1.0);
          viewer.enableAutoResize(false);
          viewer.setPlaneTable(blackBgTable);
          viewer.enableInterpolation(isInterpolated);
          if ($active_tool_idx === 0)
            viewer.setInputHandlers(VolumeViewer.INPUT_HANDLERS_INPUT);
          else if ($active_tool_idx === 1)
            viewer.setInputHandlers(VolumeViewer.INPUT_HANDLERS_SPIN);
          break;
        }
        case "3D": {
          viewer.show3DView();
          viewer.enableGradients(true);
          viewer.enableAutoResize(true);
          viewer.setBackgroundColorRGBA(0.0, 0.0, 0.0, 1.0);
          viewer.setPlaneTable(blackBgTable);
          viewer.enableInterpolation(isInterpolated);
          break;
        }
        case "MPR": {
          viewer.showMPRView();
          viewer.enableGradients(false);
          viewer.setBackgroundColorRGBA(0.4, 0.4, 0.4, 1.0);
          viewer.setPlaneTable(null);
          viewer.enableAutoResize(false);
          viewer.enableInterpolation(isInterpolated);
          break;
        }
        default:
          break;
      }
    }
  }

  // scroll
  $: if (viewer && viewMode) {
    viewer.setPlaneOffsetXYZ(
      scrollX || globalScrollX || 0,
      scrollY || globalScrollY || 0,
      scrollZ || globalScrollZ || 0
    );
  }

  // drag / touch controls (depends on view mode too)
  $: if (viewer) {
    if ($active_tool_idx === 0)
      viewer.setInputHandlers(VolumeViewer.INPUT_HANDLERS_INPUT);
    else if ($active_tool_idx === 1)
      viewer.setInputHandlers(VolumeViewer.INPUT_HANDLERS_SPIN);
  }

  // interpolation
  $: if (viewer) viewer.enableInterpolation(isInterpolated);

  // rotation
  $: if (viewer) viewer.setRotationMatrix(rotation || globalRotation);

  // luminosity (brightness & contrast)
  $: if (viewer && autoBrightness !== false && autoContrast !== false) {
    viewer.setLuminosityBrightnessAndContrast(
      brightness || autoBrightness,
      contrast || autoContrast
    );
  }

  // zoom (and zoom-to-fit)
  $: zoomToFit = viewer && renderTrigger ? viewer.calculateZoomToFit() : false;
  $: if (viewer && zoomToFit) viewer.setZoom(zoom || globalZoom || zoomToFit);

  // panning
  $: if (viewer) {
    viewer.setScreenPanXY(panX || globalPanX || 0, panY || globalPanY || 0);
  }

  // clipping
  $: if (viewer && viewMode === "3D") {
    viewer.setClipOffset(clipOffset);
  }
  $: if (viewer && viewMode === "3D") {
    if (clipNormal) {
      viewer.enableClipping();
      viewer.setClipNormal(clipNormal);
    } else {
      viewer.enableClipping(false);
    }
  }

  // color map
  $: if (viewer && activeColorMap) {
    const map = colorMaps[viewMode].find((cm) => cm.name === activeColorMap);
    if (map && map.render) {
      map.render(viewer);
      viewer.enableInterpolation(isInterpolated);
    }
  }

  // Re-render trigger
  let canvasWidth = 0;
  let canvasHeight = 0;
  let rendererElement;
  $: if (renderTrigger && rendererElement && viewer) reRender();
  function reRender() {
    tick().then(() => {
      canvasWidth = rendererElement.clientWidth;
      canvasHeight = rendererElement.clientHeight;
      viewer.invalidate();
    });
  }

  // Color Map Selection
  // - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - - |
  let activeColorMap = "";
  let showSaveDefaultColorMapPrompt = false;
  let handleColorMapChange = noop;
  let handleMakeColorMapDefault = noop;
  $: switch (viewMode) {
    case "MPR": {
      // MPR
      handleColorMapChange = (event) => (colorMapMPR = event.target.value);
      handleMakeColorMapDefault = () => {
        $defaultColorMapMPR = colorMapMPR;
        colorMapMPR = null;
      };
      activeColorMap = colorMapMPR || $defaultColorMapMPR;
      showSaveDefaultColorMapPrompt =
        colorMapMPR && colorMapMPR !== $defaultColorMapMPR;
      break;
    }
    case "3D": {
      // 3D
      handleColorMapChange = (event) => (colorMap3D = event.target.value);
      handleMakeColorMapDefault = () => {
        $defaultColorMap3D = colorMap3D;
        colorMap3D = null;
      };
      activeColorMap = colorMap3D || $defaultColorMap3D;
      showSaveDefaultColorMapPrompt =
        colorMap3D && colorMap3D !== $defaultColorMap3D;
      break;
    }
    default: {
      // 2D
      handleColorMapChange = (event) => (colorMap2D = event.target.value);
      handleMakeColorMapDefault = () => {
        $defaultColorMap2D = colorMap2D;
        colorMap2D = null;
      };
      activeColorMap = colorMap2D || $defaultColorMap2D;
      showSaveDefaultColorMapPrompt =
        colorMap2D && colorMap2D !== $defaultColorMap2D;
      break;
    }
  }

  // Cleanup
  // - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - - |
  onDestroy(() => {
    if (viewer && viewer.destroy) viewer.destroy();
  });
</script>

<main class="viewer-component h-100">
  <!-- Header w/tools -->
  <!-- - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  -->
  <header
    class="border-bottom bg-dark d-flex justify-content-between align-items-center pl-2 pr-1"
  >
    <div class="d-inline-flex align-items-center text-truncate">
      <h6 class="mb-0 mr-3 text-truncate xtra-line-height">
        {series
          ? series.getSeriesDescription()
          : translate("notifications.loadingDotDotDot")}
      </h6>
      {#if series}
        <button
          class="btn btn-sm btn-outline-light border-0 px-1 mr-1"
          type="button"
          title={translate("multiviewer.view_dicom_fields")}
          on:click={() => dispatch("showDicomFields")}
        >
          <span class="sr-only"
            >{translate("multiviewer.view_dicom_fields")}</span
          >
          <i class="fas fa-fw fa-newspaper" />
        </button>
        <a
          class="btn btn-sm btn-outline-light border-0 px-1 mr-1"
          href="#/study-details/{series.tags['0020000D'].value[0]}"
          title={translate("multiviewer.open_study_details")}
        >
          <span class="sr-only"
            >{translate("multiviewer.open_study_details")}</span
          >
          <i class="fas fa-fw fa-door-open" />
        </a>
      {/if}
    </div>
    <div class="d-flex">
      <!-- Ellipsis Button w/Menu -->
      <!-- - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  --->
      <div class="dropdown">
        <button
          type="button"
          class="btn btn-sm btn-outline-light border-0 px-1"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <i class="fas fa-fw fa-ellipsis-h" />
        </button>

        <!-- Render Mode - 2D, 3D, MPR, Interpolation, & Color Map -->
        <div class="dropdown-menu dropdown-menu-right" on:click|stopPropagation>
          <!-- Render Mode - 2D, 3D, MPR. -->
          <div class="form-group px-3 py-1">
            <label for="RenderModeInput"
              >{translate("multiviewer.render_mode")}</label
            >
            <div
              class="btn-group btn-group-sm btn-group-toggle w-100"
              data-toggle="buttons"
            >
              <button
                class="btn btn-primary"
                class:active={!viewMode || viewMode === "2D"}
                type="button"
                on:click={() => (viewMode = "2D")}
              >
                {translate("multiviewer.2d")}
              </button>
              <button
                class="btn btn-primary"
                class:active={viewMode === "3D"}
                type="button"
                on:click={() => (viewMode = "3D")}
              >
                {translate("multiviewer.3d")}
              </button>
              <button
                class="btn btn-primary"
                class:active={viewMode === "MPR"}
                type="button"
                on:click={() => (viewMode = "MPR")}
              >
                {translate("multiviewer.mpr")}
              </button>
            </div>
          </div>
          <div class="dropdown-divider" />

          <!-- Color Map Selection -->
          {#if colorMaps[viewMode].length > 1}
            <div class="form-group px-3 py-1">
              <label for="RenderModeInput"
                >{translate("multiviewer.color_map")}</label
              >
              <select
                class="custom-select"
                value={activeColorMap}
                on:change={handleColorMapChange}
              >
                {#each colorMaps[viewMode] as { name }}
                  <option value={name}>{name}</option>
                {/each}
              </select>
              {#if showSaveDefaultColorMapPrompt}
                <button
                  type="button"
                  class="btn btn-sm btn-block btn-outline-primary mt-2"
                  on:click={handleMakeColorMapDefault}
                >
                  <span>Make Default</span>
                </button>
              {/if}
            </div>
            <div class="dropdown-divider" />
          {/if}

          <!-- Interpolation Controls -->
          <div class="px-3 py-1">
            <div
              class="custom-control custom-switch ml-1 mt-2"
              on:click|stopPropagation
            >
              <input
                type="checkbox"
                class="custom-control-input"
                id={`InterpSwitch${uuid}`}
                bind:checked={isInterpolated}
              />
              <label
                class="custom-control-label pl-2"
                for={`InterpSwitch${uuid}`}
              >
                {translate("multiviewer.interpolation")}
              </label>
            </div>
          </div>
        </div>
      </div>

      <!-- Expand Button -->
      <button
        type="button"
        class="btn btn-sm btn-outline-light border-0 px-1"
        on:click={() => dispatch("expand")}
      >
        <i class="fas fa-fw fa-expand" />
      </button>

      <!-- Close Button -->
      <button
        type="button"
        class="btn btn-sm btn-outline-light border-0 px-1"
        on:click={() => dispatch("close")}
      >
        <i class="fa fa-fw fa-times" aria-hidden="true" />
      </button>
    </div>
  </header>

  <!-- Renderer -->
  <!-- - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  -->
  <div
    class="renderer position-relative overflow-hidden"
    bind:this={rendererElement}
  >
    <canvas
      class="renderer-canvas | d-block h-100 w-100 top-0 left-0 right-0 bottom-0 position-absolute"
      bind:this={canvasEl}
      tabindex="-1"
      width={canvasWidth}
      height={canvasHeight}
    />

    <!-- Metadata Overlay -->
    {#if $show_metadata && series}
      <div class="small position-absolute top-0 p-1 left-0 w-100 h-0 text-left">
        <span class="bg-black px-1" title={translate("patient_data.name")}>
          {series.getPatientName()}
        </span><br />
        <span class="bg-black px-1" title={translate("patient_data.id")}>
          {series.getPatientID()}
        </span><br />
        <span class="bg-black px-1" title={translate("patient_data.dob")}>
          {formatDate(series.tags["00100030"].value[0], "M/d/y")}
        </span><br />
        <span class="bg-black px-1" title={translate("patient_data.sex")}>
          {window.daikon.Image.getSingleValueSafely(series.tags["00100040"], 0)}
        </span><br />
        <span class="bg-black px-1" title={translate("time.conducted_at")}>
          {formatDate(series.tags["0008002A"].value[0], "M/d/y, h:mm b")}
        </span><br />
        <span
          class="bg-black px-1"
          title={translate("study_data.institution_name")}
        >
          {window.daikon.Image.getSingleValueSafely(series.tags["00080080"], 0)}
        </span>
      </div>
      <div
        class="small position-absolute top-0 p-1 right-0 w-100 h-0 text-right"
      >
        <span
          class="bg-black px-1 text-capitalize"
          title={translate("study_data.software_version")}
        >
          {window.daikon.Image.getSingleValueSafely(series.tags["00081090"], 0)}
        </span><br />
        <span
          class="bg-black px-1 text-capitalize"
          title={translate("study_data.series_field_strength")}
        >
          {window.daikon.Image.getSingleValueSafely(
            series.tags["00180087"],
            0
          )}T
        </span><br />
        <span
          class="bg-black px-1 text-capitalize"
          title={translate("study_data.sequence_name")}
        >
          {window.daikon.Image.getSingleValueSafely(series.tags["0008103E"], 0)}
        </span><br />
        <span
          class="bg-black px-1 text-capitalize"
          title={translate("study_data.contrast_type")}
        >
          {window.daikon.Image.getSingleValueSafely(series.tags["00089209"], 0)}
        </span><br />
        <span
          class="bg-black px-1 text-capitalize"
          title={translate("series_data.duration")}
        >
          {MSS(
            Math.round(
              window.daikon.Image.getSingleValueSafely(
                series.tags["00189073"],
                0
              )
            )
          )}
        </span><br />
        <span
          class="bg-black px-1 text-capitalize"
          title={translate("study_data.slice_thickness")}
        >
          {window.daikon.Image.getSingleValueSafely(series.tags["00180050"], 0)}
        </span><br />
        <span
          class="bg-black px-1"
          title={translate("study_data.pixel_spacing_xy")}
        >
          {series.getPixelSpacing().join(" / ")}
        </span>
      </div>
    {/if}

    <!-- Controls Overlay (Bottom Left) -->
    {#if viewer}
      <ViewerControls
        volumeViewer={viewer}
        on:reclip={handleReclip}
        {autoBrightness}
        {autoContrast}
        {viewMode}
        {globalRotation}
        {zoomToFit}
        bind:rotation
        bind:brightness
        bind:contrast
        bind:zoom
        bind:globalZoom
        bind:scrollZ
        bind:scrollY
        bind:scrollX
        bind:panX
        bind:panY
        bind:globalScrollZ
        bind:globalScrollY
        bind:globalScrollX
        bind:globalPanX
        bind:globalPanY
        bind:clipOffset
      />
    {/if}
    {#await series_promise}
      <div
        class="text-center spinner-delayed pt-5 position-absolute top-0 left-0 w-100"
      >
        <div class="spinner-border" role="status">
          <span class="sr-only">
            {translate("notifications.loadingDotDotDot")}
          </span>
        </div>
      </div>
    {:catch err}
      <h6 class="text-center">{err}</h6>
    {/await}
  </div>
</main>

<style type="text/scss">@font-face {
  font-family: "Neris";
  src: url("/fonts/Neris-Regular-webfont.woff") format("woff"), url("/fonts/Neris-Regular-webfont.woff2") format("woff2");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: "Neris";
  src: url("/fonts/Neris-Italic-webfont.woff") format("woff"), url("/fonts/Neris-Italic-webfont.woff2") format("woff2");
  font-weight: 400;
  font-style: italic; }

@font-face {
  font-family: "Neris";
  src: url("/fonts/Neris-Light-webfont.woff") format("woff"), url("/fonts/Neris-Light-webfont.woff2") format("woff2");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: "Neris";
  src: url("/fonts/Neris-LightItalic-webfont.woff") format("woff"), url("/fonts/Neris-LightItalic-webfont.woff2") format("woff2");
  font-weight: 300;
  font-style: italic; }

@font-face {
  font-family: "Neris";
  src: url("/fonts/Neris-Black-webfont.woff") format("woff"), url("/fonts/Neris-Black-webfont.woff2") format("woff2");
  font-weight: 900;
  font-style: normal; }

@font-face {
  font-family: "Neris";
  src: url("/fonts/Neris-BlackItalic-webfont.woff") format("woff"), url("/fonts/Neris-BlackItalic-webfont.woff2") format("woff2");
  font-weight: 900;
  font-style: italic; }

header {
  height: 2.125rem; }

.renderer {
  height: calc(100% - 2.125rem); }

.renderer-canvas:focus {
  outline: 2px dashed #5358b5 !important;
  outline-offset: -3px; }

.xtra-line-height {
  line-height: 1.5; }

/*# sourceMappingURL=x.map */</style>
