<script>
  // Import external dependencies.
  import { translate } from "i18n"; //eslint-disable-line import/no-unresolved
  import modalpowers from "../helpers/action-modal";

  const keyboard_shortcuts = [
    {
      one: "shift",
      two: "R",
      hint: "reset_orientation",
    },
    {
      one: "shift",
      two: "0",
      hint: "fit_viewport",
    },
    {
      one: "0",
      hint: "reset_input_wind",
    },
    {
      one: "shift",
      two: "arrows",
      hint: "pans",
    },
    {
      one: "shift",
      two: "plus_minus",
      hint: "zoom",
    },
    {
      one: "shift",
      two: "brackets",
      hint: "rotate_90",
    },
    {
      one: "shift",
      two: "H",
      hint: "horizontal_flip",
    },
    {
      one: "shift",
      two: "V",
      hint: "vertical_flip",
    },
    {
      one: "shift",
      two: "alt",
      three: "1",
      hint: "reo_coronal",
    },
    {
      one: "shift",
      two: "alt",
      three: "2",
      hint: "reo_sagittal",
    },
    {
      one: "shift",
      two: "alt",
      three: "3",
      hint: "reo_axial",
    },
    {
      one: "1",
      hint: "reo_anterior",
    },
    {
      one: "2",
      hint: "reo_posterior",
    },
    {
      one: "3",
      hint: "reo_left",
    },
    {
      one: "4",
      hint: "reo_right",
    },
    {
      one: "5",
      hint: "reo_superior",
    },
    {
      one: "6",
      hint: "reo_inferior",
    },
    {
      one: "up_down",
      hint: "move_clip",
    },
    {
      one: "home",
      hint: "first_slice",
    },
    {
      one: "end",
      hint: "last_slice",
    },
  ];
</script>

<div
  class="modal fade"
  id="MultiviewerKeyboardShortcutModal"
  tabindex="-1"
  aria-labelledby="MultiviewerKeyboardShortcutModalLabel"
  aria-hidden="true"
  use:modalpowers
>
  <div class="modal-dialog modal-xl modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title" id="MultiviewerKeyboardShortcutModalLabel">
          Keyboard Shortcuts
        </h1>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <!-- Core Content -->
      <div class="modal-body">
        <!-- Render all Shortcuts -->
        <ul class="list-group mb-3">
          {#each keyboard_shortcuts as { one, two, three, hint } (hint)}
            <li
              class="list-group-item d-flex justify-content-between
              align-items-center"
            >
              <span>{translate(`multiviewer.kb_shorts.${hint}`)}</span>
              <div class="ws-nowrap">
                <kbd class="border border-light">
                  <kbd>{translate(`multiviewer.kb_shorts.${one}`)}</kbd>
                  {#if two}
                    +
                    <kbd>{translate(`multiviewer.kb_shorts.${two}`)}</kbd>
                  {/if}
                  {#if three}
                    +
                    <kbd>{translate(`multiviewer.kb_shorts.${three}`)}</kbd>
                  {/if}
                </kbd>
              </div>
            </li>
          {/each}
        </ul>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          Close
        </button>
      </div>
    </div>
  </div>
</div>

<style type="text/scss">/* Sass Variables */
@font-face {
  font-family: "Neris";
  src: url("/fonts/Neris-Regular-webfont.woff") format("woff"), url("/fonts/Neris-Regular-webfont.woff2") format("woff2");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: "Neris";
  src: url("/fonts/Neris-Italic-webfont.woff") format("woff"), url("/fonts/Neris-Italic-webfont.woff2") format("woff2");
  font-weight: 400;
  font-style: italic; }

@font-face {
  font-family: "Neris";
  src: url("/fonts/Neris-Light-webfont.woff") format("woff"), url("/fonts/Neris-Light-webfont.woff2") format("woff2");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: "Neris";
  src: url("/fonts/Neris-LightItalic-webfont.woff") format("woff"), url("/fonts/Neris-LightItalic-webfont.woff2") format("woff2");
  font-weight: 300;
  font-style: italic; }

@font-face {
  font-family: "Neris";
  src: url("/fonts/Neris-Black-webfont.woff") format("woff"), url("/fonts/Neris-Black-webfont.woff2") format("woff2");
  font-weight: 900;
  font-style: normal; }

@font-face {
  font-family: "Neris";
  src: url("/fonts/Neris-BlackItalic-webfont.woff") format("woff"), url("/fonts/Neris-BlackItalic-webfont.woff2") format("woff2");
  font-weight: 900;
  font-style: italic; }

.tutorial-modal :global(.modal-content) {
  border-top-color: #65C2FF; }

/*# sourceMappingURL=x.map */</style>
