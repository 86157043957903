<svelte:options immutable={true} />

<script>
  // Import external dependencies.
  import { translate, formatDate, parseDate } from "i18n"; //eslint-disable-line import/no-unresolved
  import uniq from "lodash-es/uniq";
  import uniqBy from "lodash-es/uniqBy";
  import noop from "lodash-es/noop";
  import { getContext, tick } from "svelte";
  import { queryParameters, updateQueryParameters } from "../../stores/router";
  import { loadingSpinnerHTML, MSS } from "../../helpers/formatters";
  import {
    addWildcardTag,
    parseDICOMwebJSON,
    sortByAcquisitionDesc,
  } from "../../helpers/dicomweb";
  import modalpowers from "../../helpers/action-modal";
  import SearchBar from "../../components/search-bar.svelte";
  import { api, OrgQP, SelectedOrg } from "../../helpers/api";
  import user from "../../stores/user";
  import { showAlert } from "../../stores/alerts";
  import OrgSelector from "../../components/org-selector.svelte";
  import TagSelector from "../../components/tag-selector.svelte";

  // Parse Study UIDs from the URL.
  const series_uids_qp = $queryParameters.series || "";
  const series_uids = series_uids_qp.split(",").filter((s) => !!s);
  const study_uids = uniq(series_uids.map((uid) => uid.split(":")[0]));

  // Request all of the Series DICOM of the specific studies.
  const siblign_studies_promise = Promise.all(
    study_uids.map(async (uid) => {
      const raw_series_dicom_json = await api.get(
        `/studies/${uid}/series?${$OrgQP}&includefield=0008103E,0020000E,00189073,00180081,00180050,00101002,0008002A`
      );
      return parseDICOMwebJSON(raw_series_dicom_json);
    })
  );

  // Wait for the Series Daikon Promises to all resolve…
  // Then, parse the Patient's name and ID, de-duplicating.
  const series_promises = getContext("series_promises") || [];
  const list_of_patients_promise = Promise.all(
    Object.values(series_promises)
  ).then((arrayOfSeriesDaikons) => {
    return uniqBy(
      arrayOfSeriesDaikons.map((s) => ({
        id: s.getPatientID(),
        name: s.getPatientName(),
      })),
      "id"
    );
  });

  // Studies Search
  // - -  - -  - -  - -  - -  - -  - - - -  - -  - -  - -  - -  - - - -  - -  -|

  // Template-bound properties.
  const perPage = 20;
  let searchTxt = "";
  let selectedPatientId = false;
  let study_search_http_in_flight = false;
  let study_search_load_offset = 0;
  let study_search_can_load_more = false;
  let study_search_results = [];
  let dicomTag = "";

  // Helper to cancel in-flight requests.
  let abortHTTP = noop;

  // Initialize patient selection to the first patient or "All" if none found.
  list_of_patients_promise.then((patients) => {
    selectedPatientId = patients && patients.length ? patients[0].id : "";
  });

  // Handle Search Text Entry change.
  async function handleSearchTxtChange({ detail: txt }) {
    abortHTTP();
    await tick();
    await tick();
    searchTxt = txt;
    study_search_results = [];
    study_search_load_offset = 0;
  }

  async function handleDicomTagChange({ detail: txt }) {
    abortHTTP();
    await tick();
    await tick();
    dicomTag = txt;
    study_search_results = [];
    study_search_load_offset = 0;
  }

  // REACTIVELY request Studies filtered to the given PATIENT, SEARCH, AND PAGINATION IDX.
  $: if (selectedPatientId !== false) {
    performSearch(
      searchTxt,
      dicomTag,
      selectedPatientId,
      study_search_load_offset,
      $OrgQP
    );
  }
  function performSearch() {
    //
    // Format the DICOMweb request for studies.
    // let studies_url = `/studies?${$OrgQP}&includefield=0020000D,00142018,0008002A,00081030&limit=${perPage}`; for when we need to revert
    let studies_url = `/quickload-studies?${$OrgQP}`;
    const urlParams = { limit: perPage, offset: study_search_load_offset };

    if (selectedPatientId) {
      urlParams["00100020"] = selectedPatientId;
    }
    if (dicomTag && searchTxt) {
      urlParams[dicomTag] = addWildcardTag(dicomTag, searchTxt);
    }
    studies_url +=
      ($OrgQP ? "&" : "") + new URLSearchParams(urlParams).toString();

    // Prepare our cancelation mechanism.
    let signal;
    if ("AbortController" in window) {
      const controller = new AbortController();
      signal = controller.signal;
      abortHTTP = () => controller.abort();
    }

    // Execute the HTTP request;
    study_search_http_in_flight = true;
    api
      .get(studies_url, { signal })
      .then((new_studies) => {
        study_search_http_in_flight = false;
        study_search_results = [
          ...study_search_results,
          ...parseDICOMwebJSON(new_studies),
        ];
        study_search_results.sort(sortByAcquisitionDesc);

        // enable the "Load More" button if we received a full load.
        study_search_can_load_more = new_studies.length >= perPage;
      })
      .catch((err) => {
        study_search_http_in_flight = false;
        if (err && err.name !== "AbortError") {
          showAlert({
            message: err,
          });
        }
      });
  }

  // Clear search results when the user selects a different patient filter.
  $: if (selectedPatientId !== false) {
    study_search_results = [];
    study_search_load_offset = 0;
  }

  // Wipe the results if the Org filter changes.
  SelectedOrg.subscribe(() => {
    study_search_results = [];
    study_search_load_offset = 0;
  });

  // Series/Study Selection
  // - -  - -  - -  - -  - -  - -  - - - -  - -  - -  - -  - -  - - - -  - -  -|
  let selected_series = [];
  let selected_studies = [];
  function selectOrDeselectSeries(series) {
    if (selected_series.includes(series)) {
      selected_series = selected_series.filter((s) => s !== series);
    } else {
      selected_series = [...selected_series, series];
    }
  }
  function selectOrDeselectStudy(study) {
    if (selected_studies.includes(study)) {
      selected_studies = selected_studies.filter((s) => s !== study);
    } else {
      selected_studies = [...selected_studies, study];
    }
  }
  function addSelectedToMultiviewer() {
    const new_series_combo_ids = selected_series.map(
      (s) => `${s.StudyInstanceUID}:${s.SeriesInstanceUID}`
    );
    const new_study_ids = selected_studies.map((s) => s.StudyInstanceUID);
    updateQueryParameters(
      {
        modal: null,
        series: $queryParameters.series
          ? $queryParameters.series
              .split(",")
              .concat(new_series_combo_ids)
              .join(",")
          : null,
        studies: new_study_ids.length ? new_study_ids.join(",") : null,
      },
      false
    );
  }
</script>

<div
  class="modal fade"
  id="AddSeriesModal"
  tabindex="-1"
  aria-labelledby="AddSeriesModalLabel"
  aria-hidden="true"
  use:modalpowers
>
  <div class="modal-dialog modal-xl modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title" id="AddSeriesModalLabel">Add Series</h1>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <!-- Sibling Series Cards -->
        <!-- - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - - -->
        {#await siblign_studies_promise}
          {@html loadingSpinnerHTML("my-5")}
        {:then sibling_studies}
          {#if sibling_studies.length}
            <h5 class="pt-3">Related Series</h5>
            <div class="container-fluid px-0">
              {#each sibling_studies as sibling_study, i}
                {#if sibling_studies.length > 1}
                  <div class="text-muted letter-space-1 small">
                    {sibling_studies[i][0].PatientName} ({sibling_studies[i][0]
                      .PatientID}) - Conducted On: {formatDate(
                      parseDate(sibling_studies[i][0].AcquisitionDateTime),
                      "M/d/y, h:mm b"
                    )}
                  </div>
                {/if}
                <div class="form-row">
                  {#each sibling_study as series, i2 (series.SeriesInstanceUID)}
                    <div
                      class="col-12 col-sm-6 col-lg-4 col-xl-3 pb-3 text-dark"
                    >
                      <label
                        class="card border-dark bg-dark text-light position-relative cursor-pointer h-100"
                        class:selected={selected_series.includes(series)}
                        for="select-sibling-series-check-{i}{i2}"
                      >
                        <div class="card-body d-flex flex-column">
                          <h5 class="card-title">{series.SeriesDescription}</h5>
                          <div
                            class="card-text d-flex justify-content-between mt-auto"
                          >
                            <div>
                              <span class="small text-muted letter-space-1">
                                {translate("series_data.duration")}
                              </span>
                              <br />
                              <span>
                                {series.AcquisitionDuration
                                  ? MSS(~~series.AcquisitionDuration)
                                  : translate("not_available_short")}
                              </span>
                            </div>
                            <div>
                              <span class="small text-muted letter-space-1">
                                {translate("series_data.echo_time")}
                              </span>
                              <br />
                              <span
                                >{series.EchoTime
                                  ? MSS(~~series.EchoTime)
                                  : translate("not_available_short")}</span
                              >
                            </div>
                          </div>
                        </div>
                        <!-- Select Checkbox -->
                        <div class="position-absolute top-0 right-0 p-1">
                          <div class="custom-control custom-checkbox my-auto">
                            <input
                              type="checkbox"
                              class="custom-control-input cursor-pointer"
                              id="select-sibling-series-check-{i}{i2}"
                              on:change={() => selectOrDeselectSeries(series)}
                            />
                            <label
                              class="custom-control-label cursor-pointer"
                              for="select-sibling-series-check-{i}{i2}"
                            />
                          </div>
                        </div>
                      </label>
                    </div>
                  {/each}
                </div>
              {/each}
            </div>
          {/if}
        {/await}

        <!-- Search Studies - List of Studies with search and filter by patient -->
        <!-- - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - - -->
        {#await list_of_patients_promise}
          {@html loadingSpinnerHTML("my-5")}
        {:then list_of_patients}
          <h3 class="mt-3">Search Studies</h3>
          {#if list_of_patients.length}
            <nav class="nav nav-pills nav-fill pb-2">
              {#each list_of_patients as { id, name } (id)}
                <button
                  type="button"
                  class="nav-link btn"
                  class:active={id === selectedPatientId}
                  on:click={() => (selectedPatientId = id)}
                >
                  {name}
                </button>
              {/each}
              <button
                type="button"
                class="nav-link btn"
                class:active={!selectedPatientId}
                on:click={() => (selectedPatientId = "")}
              >
                All
              </button>
            </nav>
          {/if}

          <!-- Search & Filter -->
          <div class="container-fluid px-0">
            <div class="form-row">
              <div class="col-12 col-md-3 mb-3 mb-md-0">
                <TagSelector
                  {dicomTag}
                  on:dicomTagChange={handleDicomTagChange}
                />
              </div>
              <div class="col">
                <SearchBar
                  className="pb-2"
                  on:searchTxtChange={handleSearchTxtChange}
                  helpCopy={translate("study_data.search_help")}
                />
              </div>

              <!-- Org Filter Selection -->
              {#if $user.app_metadata.is_hyperfine_admin}
                <div class="col-12 col-md-3 mt-3 mt-md-0 mb-3 mb-md-0">
                  <OrgSelector />
                </div>
              {/if}
            </div>
          </div>

          <div class="container-fluid">
            {#each study_search_results as study, i}
              <label
                class="study-row row cursor-pointer | border rounded mb-2"
                for="select-series-check-{i}"
                class:selected={selected_studies.includes(study)}
              >
                <!-- Select Checkbox -->
                <label
                  class="select-checkbox-cell | col-auto p-2 m-0 border-right d-flex cursor-pointer"
                  for="select-series-check-{i}"
                >
                  <div class="custom-control custom-checkbox my-auto">
                    <input
                      type="checkbox"
                      class="custom-control-input cursor-pointer"
                      id="select-series-check-{i}"
                      on:change={() => selectOrDeselectStudy(study)}
                    />
                    <label
                      class="custom-control-label cursor-pointer"
                      for="select-series-check-{i}"
                    />
                  </div>
                </label>

                <div class="col py-2">
                  <div class="form-row">
                    <!-- Patient Info - Name, Date of Birth, & MRN -->
                    <div class="col-12 col-sm-5 col-md-6 col-lg-5">
                      <div class="row">
                        <!-- Patient Name -->
                        <h5
                          class="col-12 col-md-6 text-truncate mb-1"
                          title={translate("patient_data.name")}
                        >
                          {study.PatientName}
                        </h5>

                        <!-- MRN (Medical Records Number) -->
                        <div class="col-md-6 text-truncate pt-little mb-0">
                          <span class="small text-muted letter-space-1">
                            {translate(
                              "study_data.medical_records_number_short"
                            )}
                          </span>
                          <span class="small text-muted d-md-none">:</span>
                          <br class="d-none d-md-block" />
                          <span class="row-val">
                            {@html study.PatientID || "&nbsp;"}
                          </span>
                        </div>

                        <!-- Date of Birth -->
                        <div
                          class="col-12 col-md-6 text-truncate small text-muted
                        mt-md--4 pt-md-1"
                        >
                          <span class="letter-space-1">
                            {translate("patient_data.dob", 1)} :
                          </span>
                          <span>
                            {#if study.PatientBirthDate}
                              {formatDate(
                                parseDate(study.PatientBirthDate),
                                "M/d/y"
                              )}
                            {/if}
                          </span>
                        </div>
                      </div>
                    </div>

                    <!-- Study Info -->
                    <div class="col-sm">
                      <div class="row">
                        <!-- Summary Header (card-view only) -->
                        <div class="col-12 d-md-none">
                          <h6 class="summary-header mt-3 mt-sm-0">
                            {translate("study_data.summary")}
                          </h6>
                        </div>

                        <!-- Conducted At -->
                        <div
                          class="col-12 col-md-auto order-md-last text-truncate
                        pt-little pr-xl-5"
                        >
                          <span class="small text-muted letter-space-1">
                            {translate("study_data.conducted_at")}
                          </span>
                          <span class="small text-muted d-md-none">:</span>
                          <br class="d-none d-md-block" />
                          <span class="row-val">
                            {#if study.AcquisitionDateTime}
                              {formatDate(
                                parseDate(study.AcquisitionDateTime),
                                "M/d/y, h:mm b"
                              )}
                            {/if}
                          </span>
                        </div>

                        <!-- Indication -->
                        <div
                          class="col-12 col-md order-md-first text-truncate pt-little"
                        >
                          <span class="small text-muted letter-space-1">
                            {translate("study_data.indication_description")}
                          </span>
                          <span class="small text-muted d-md-none">:</span>
                          <br class="d-none d-md-block" />
                          <span class="row-val">
                            {@html study.IndicationDescription ||
                              study.StudyDescription ||
                              "&nbsp;"}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </label>
            {/each}

            <!-- Load More Button & Loading Spinner -->
            <div class="d-flex justify-content-center mt-3">
              {#if study_search_can_load_more}
                <button
                  class="btn btn-outline-primary"
                  disabled={study_search_http_in_flight}
                  on:click={() =>
                    (study_search_load_offset = study_search_results.length)}
                >
                  <span>{translate("form_labels.load_more")}</span>
                </button>
              {/if}
              {#if study_search_http_in_flight}
                <div class="animated fadeIn w-0">
                  <div class="spinner-border small ml-3" role="status">
                    <span class="sr-only">{translate("loadingDotDotDot")}</span>
                  </div>
                </div>
              {/if}
            </div>
          </div>
        {/await}
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          Cancel
        </button>
        <button
          type="button"
          class="btn btn-primary"
          on:click={addSelectedToMultiviewer}
          disabled={!selected_series.length && !selected_studies.length}
          >Add Selected</button
        >
      </div>
    </div>
  </div>
</div>

<style type="text/scss">@font-face {
  font-family: "Neris";
  src: url("/fonts/Neris-Regular-webfont.woff") format("woff"), url("/fonts/Neris-Regular-webfont.woff2") format("woff2");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: "Neris";
  src: url("/fonts/Neris-Italic-webfont.woff") format("woff"), url("/fonts/Neris-Italic-webfont.woff2") format("woff2");
  font-weight: 400;
  font-style: italic; }

@font-face {
  font-family: "Neris";
  src: url("/fonts/Neris-Light-webfont.woff") format("woff"), url("/fonts/Neris-Light-webfont.woff2") format("woff2");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: "Neris";
  src: url("/fonts/Neris-LightItalic-webfont.woff") format("woff"), url("/fonts/Neris-LightItalic-webfont.woff2") format("woff2");
  font-weight: 300;
  font-style: italic; }

@font-face {
  font-family: "Neris";
  src: url("/fonts/Neris-Black-webfont.woff") format("woff"), url("/fonts/Neris-Black-webfont.woff2") format("woff2");
  font-weight: 900;
  font-style: normal; }

@font-face {
  font-family: "Neris";
  src: url("/fonts/Neris-BlackItalic-webfont.woff") format("woff"), url("/fonts/Neris-BlackItalic-webfont.woff2") format("woff2");
  font-weight: 900;
  font-style: italic; }

.card {
  transition: border-color 0.2s ease-out;
  border-top-color: #5358b5 !important;
  border-width: 2px; }
  .card:hover, .card.selected {
    border-color: #5358b5 !important; }

.study-row {
  transition: border-top-color 0.2s ease-out; }
  .study-row:hover, .study-row.selected {
    border-top-color: #ED6437 !important; }

/*# sourceMappingURL=x.map */</style>
