<script>
  // Import external dependencies.
  import { translate, parseDate, formatDate } from "i18n"; //eslint-disable-line import/no-unresolved
  import { showAlert } from "../stores/alerts";
  import user from "../stores/user";
  import { parseDICOMwebJSON } from "../helpers/dicomweb";
  import { MSS } from "../helpers/formatters";
  import { api, OrgQP } from "../helpers/api";
  import SearchBar from "../components/search-bar.svelte";
  import { queryParameters, updateQueryParameters } from "../stores/router";

  // Component Props & Constants
  export let studyId = "";

  // Load Study Details
  // - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - - |
  let study = false;
  let studyHttpInFlight = false;
  let series = false;
  let seriesHttpInFlight = false;
  $: loadStudy(studyId);
  function loadStudy() {
    //
    // Format the DICOMweb requests.
    const qp =
      $user && $user.app_metadata.is_hyperfine_admin ? "" : `${$OrgQP}&`;
    const study_url = `/studies?${qp}0020000D=${studyId}&includefield=0008002A,00080090,00081090,00181020,00180087,00142018,00081030,00080080,00181000,00100030,00100040`;
    const series_url = `/studies/${studyId}/series?${qp}includefield=0008103E,0020000E,00189073,00180081,00180050,00101002`;

    // Execute the Study request
    study = false;
    studyHttpInFlight = true;
    api
      .get(study_url)
      .then((new_studies) => {
        studyHttpInFlight = false;
        study = parseDICOMwebJSON(new_studies)[0];
      })
      .catch((err) => {
        studyHttpInFlight = false;
        showAlert({ message: err });
      });

    // Execute the Series request
    series = false;
    seriesHttpInFlight = true;
    api
      .get(series_url)
      .then((new_series) => {
        seriesHttpInFlight = false;
        series = parseDICOMwebJSON(new_series);
      })
      .catch((err) => {
        seriesHttpInFlight = false;
        showAlert({ message: err });
      });
  }

  // Series Selection & Openning
  // - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - - |
  let selected_series = [];
  function checkAllOrNone() {
    selected_series = selected_series.length === series.length ? [] : series;
  }
  function selectSeriesOrNot(the_series) {
    if (selected_series.includes(the_series))
      selected_series = selected_series.filter((s) => s !== the_series);
    else selected_series = [...selected_series, the_series];
  }
  $: viewerHref = compute_viewerHref(selected_series, series);
  function compute_viewerHref() {
    if (!selected_series.length || selected_series.length === series.length)
      return `#/multiviewer?studies=${studyId}`;
    return `#/multiviewer?series=${selected_series
      .map((s) => `${studyId}:${s.SeriesInstanceUID}`)
      .toString()}`;
  }

  // Search - Selects series that match the query
  // - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - - |
  let searchTxt = $queryParameters.search || "";
  function handleSearchTxtChange({ detail: txt }) {
    searchTxt = txt;
    updateQueryParameters({ search: txt || null });
  }
  $: if (series) {
    selected_series = searchTxt
      ? series.filter((s) =>
          s.SeriesDescription.toLowerCase().includes(searchTxt.toLowerCase())
        )
      : [];
  }
</script>

<section class="study-details-component" data-component="study-details">
  <!-- Loading & Not Found States -->
  {#if !study}
    {#if studyHttpInFlight}
      <div class="py-5 text-center spinner-delayed">
        <div class="spinner-border" role="status">
          <span class="sr-only">
            {translate("notifications.loadingDotDotDot")}
          </span>
        </div>
      </div>
    {:else}
      <h3 class="my-5 text-center">{translate("study_data.not_found")}</h3>
    {/if}
  {/if}

  <!-- Actual Loaded Contents… -->
  {#if study}
    <div class="container-fluid fade-in">
      <div class="row">
        <!-- Summary Panel -->
        <div class="col-12 col-md-5 col-xl-4 | pb-4">
          <section
            class="summary | p-4 border rounded position-relative shadow"
          >
            <div class="title-bgblock left-0 top-0 position-absolute w-100" />
            <h1
              class="patient-name break-word"
              class:h2={study.PatientName && study.PatientName.length > 20}
              data-test="patient-name"
              title={study.PatientName}
            >
              {study.PatientName}
            </h1>
            <span class="badge badge-studies mr-2">
              {translate("modules.study", 1)}
            </span>

            <!-- Overview -->
            <div class="details-list | mt-4 mt-md-5">
              <small
                class="text-muted text-capitalize letter-space-1 d-block mb-2"
              >
                {translate("overview", 1)}
              </small>

              <!-- MRN -->
              <div class="row d-flex justify-content-between py-1">
                <span class="col-auto text-capitalize">
                  {translate("study_data.medical_records_number_short", 1)}
                </span>
                <span class="col text-right text-truncate">
                  <span
                    title={study.PatientID || translate("not_available_short")}
                    data-test="mrn"
                  >
                    {study.PatientID || translate("not_available_short")}
                  </span>
                </span>
              </div>

              <!-- Patient Date of Birth -->
              <div class="row d-flex justify-content-between py-1">
                <span class="col-auto text-capitalize">
                  {translate("study_data.patient_dob")}
                </span>
                <span class="col text-right text-truncate">
                  {#if study.PatientBirthDate}
                    <span
                      title={formatDate(
                        parseDate(study.PatientBirthDate),
                        "M/d/y"
                      )}
                    >
                      {formatDate(parseDate(study.PatientBirthDate), "M/d/y")}
                    </span>
                  {:else}{translate("not_available_short")}{/if}
                </span>
              </div>

              <!-- Patient Sex -->
              <div class="row d-flex justify-content-between py-1">
                <span class="col-auto text-capitalize">
                  {translate("study_data.patient_sex")}
                </span>
                <span class="col text-right text-truncate">
                  <span
                    title={study.PatientSex || translate("not_available_short")}
                  >
                    {study.PatientSex || translate("not_available_short")}
                  </span>
                </span>
              </div>

              <!-- Conducted At -->
              <div class="row d-flex justify-content-between py-1">
                <span class="col-auto text-capitalize">
                  {translate("time.conducted_at")}
                </span>
                <span
                  class="col text-right text-truncate"
                  data-test="conducted-at"
                >
                  {#if study.AcquisitionDateTime}
                    {formatDate(
                      parseDate(study.AcquisitionDateTime),
                      "M/d/y, h:mm b"
                    )}
                  {:else}{translate("not_available_short")}{/if}
                </span>
              </div>

              <!-- Referring Physician -->
              <div class="row d-flex justify-content-between py-1">
                <div class="col-auto">
                  {translate("people_roles.referring_physician")}
                </div>
                <div class="col text-right text-truncate">
                  {study.ReferringPhysicianName ||
                    translate("not_available_short")}
                </div>
              </div>

              <!-- Institution Name -->
              <div
                class="organizations | row d-flex justify-content-between py-1"
              >
                <div class="col-auto">
                  {translate("study_data.institution_name")}
                </div>
                <div class="col text-right text-truncate">
                  <span>
                    {study.InstitutionName || translate("not_available_short")}
                  </span>
                </div>
              </div>
            </div>

            <!-- Device Info -->
            <div class="details-list | mt-4">
              <small
                class="text-muted text-capitalize letter-space-1 d-block mb-2"
              >
                {translate("study_data.device")}
              </small>

              <!-- Model -->
              <div class="row d-flex justify-content-between py-1">
                <span class="col-auto text-capitalize">
                  {translate("study_data.model")}
                </span>
                <span class="col text-right text-truncate text-capitalize">
                  <span>
                    {study.ManufacturerModelName ||
                      translate("not_available_short")}
                  </span>
                </span>
              </div>

              <!-- Software Version -->
              <div class="row d-flex justify-content-between py-1">
                <span class="col-auto text-capitalize">
                  {translate("study_data.software_version")}
                </span>
                <span class="col text-right text-truncate text-capitalize">
                  <span>
                    {study.SoftwareVersions || translate("not_available_short")}
                  </span>
                </span>
              </div>

              <!-- Field Strength -->
              <div class="row d-flex justify-content-between py-1">
                <span class="col-auto">
                  {translate("study_data.series_field_strength")}
                </span>
                <span class="col text-right text-truncate">
                  <span>
                    {study.MagneticFieldStrength
                      ? `${study.MagneticFieldStrength}T`
                      : translate("not_available_short")}
                  </span>
                </span>
              </div>

              <!-- Device ID -->
              <div class="row d-flex justify-content-between py-1">
                <span class="col-auto">
                  {translate("study_data.device_id")}
                </span>
                <span class="col text-right text-truncate">
                  <span>
                    {study.DeviceSerialNumber ||
                      translate("not_available_short")}
                  </span>
                </span>
              </div>
            </div>

            <!-- Indication -->
            <div class="details-list | mt-4">
              <small
                class="text-muted text-capitalize letter-space-1 d-block mb-2"
              >
                {translate("study_data.indication_description")}
              </small>
              <p>
                {study.IndicationDescription ||
                  study.StudyDescription ||
                  translate("not_available_short")}
              </p>
            </div>
          </section>
        </div>

        <!-- List of Series -->
        <div class="col-12 col-md-7 col-xl-8 | pb-4">
          <!-- Controls - Check All, Search, Open Series Button -->
          <div class="form-row">
            <!-- Select All Checkbox -->
            <div class="col-auto pb-2">
              <label
                class="select-checkbox-cell | border border-black h-100 mb-0
                px-2 py-1 d-flex align-items-center border-right"
                for="selectAllSeries"
              >
                <div class="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="selectAllSeries"
                    on:change={checkAllOrNone}
                    checked={series && selected_series.length === series.length}
                  />
                  <label class="custom-control-label" for="selectAllSeries" />
                </div>
              </label>
            </div>

            <!-- Search Input -->
            <div class="col pb-2">
              <SearchBar
                {searchTxt}
                on:searchTxtChange={handleSearchTxtChange}
                debounceTime={100}
                helpCopy={translate("series_data.search_help")}
              />
            </div>

            <!-- Open Viewer Button -->
            <div class="col-12 col-sm-auto col-md-12 col-lg-auto pb-2">
              <a
                class="btn btn-secondary btn-block text-truncate"
                href={viewerHref}
              >
                {selected_series.length
                  ? translate(
                      "study_data.view_selected_series",
                      selected_series.length
                    )
                  : translate("study_data.view_all_series", 99)}
              </a>
            </div>
          </div>

          <!-- List of Series -->
          <!-- - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -->

          <!-- Loading Spinner -->
          {#if seriesHttpInFlight}
            <div class="py-5 text-center spinner-delayed">
              <div class="spinner-border" role="status">
                <span class="sr-only">
                  {translate("notifications.loadingDotDotDot")}
                </span>
              </div>
            </div>

            <!-- Empty State -->
          {:else if !series}
            <h3 class="my-5 text-center">
              {translate("series_data.none_found")}
            </h3>

            <!-- Loaded List of Series -->
          {:else}
            <div class="container-fluid">
              {#each series as the_series, i}
                <div
                  class="series-row row | border rounded mb-2"
                  class:chosen={selected_series.includes(the_series)}
                  data-test="SeriesRow"
                >
                  <!-- Select Checkbox -->
                  <label
                    class="select-checkbox-cell | col-auto p-2 m-0 border-right
                    d-flex cursor-pointer"
                    for="select-series-check-{i}"
                  >
                    <div class="custom-control custom-checkbox my-auto">
                      <input
                        type="checkbox"
                        class="custom-control-input cursor-pointer"
                        id="select-series-check-{i}"
                        on:change={() => selectSeriesOrNot(the_series)}
                        checked={selected_series.includes(the_series)}
                      />
                      <label
                        class="custom-control-label cursor-pointer"
                        for="select-series-check-{i}"
                      />
                    </div>
                  </label>

                  <a
                    class="clickable-row col"
                    href={`#/multiviewer?series=${studyId}:${the_series.SeriesInstanceUID}`}
                  >
                    <div class="row">
                      <!-- Series Description -->
                      <div class="col-6 col-sm col-md-6 col-lg py-2">
                        <h5 class="mb-1" data-test="SeriesDescription">
                          {the_series.SeriesDescription}
                        </h5>
                        <span class="badge badge-series mr-2">
                          {translate("modules.series", 1)}
                        </span>
                      </div>

                      <!-- Acquisition Duration -->
                      <div class="col-6 col-sm-2 col-md-6 col-lg-2 py-2">
                        <span class="small text-muted letter-space-1">
                          {translate("series_data.duration")}
                        </span>
                        <br />
                        <span>
                          {the_series.AcquisitionDuration
                            ? MSS(~~the_series.AcquisitionDuration)
                            : translate("not_available_short")}
                        </span>
                      </div>

                      <!-- Echo Time -->
                      <div
                        class="col-6 col-sm-3 col-md-6 col-lg-3 col-xl-2 py-2"
                      >
                        <span class="small text-muted letter-space-1">
                          {translate("series_data.echo_time")}
                        </span>
                        <br />
                        <span>
                          {the_series.EchoTime
                            ? MSS(~~the_series.EchoTime)
                            : translate("not_available_short")}
                        </span>
                      </div>

                      <!-- Slice Thickness -->
                      <div
                        class="col-6 col-sm-3 col-md-6 col-lg-auto col-xxl-2
                        py-2 text-truncate"
                      >
                        <span class="small text-muted letter-space-1">
                          {translate("series_data.slice_thickness")}
                        </span>
                        <br />
                        <span>
                          {the_series.SliceThickness ||
                            translate("not_available_short")}
                        </span>
                      </div>
                    </div>
                  </a>
                </div>
              {/each}
            </div>
          {/if}
        </div>
      </div>
    </div>
  {/if}
</section>

<style type="text/scss">@font-face {
  font-family: "Neris";
  src: url("/fonts/Neris-Regular-webfont.woff") format("woff"), url("/fonts/Neris-Regular-webfont.woff2") format("woff2");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: "Neris";
  src: url("/fonts/Neris-Italic-webfont.woff") format("woff"), url("/fonts/Neris-Italic-webfont.woff2") format("woff2");
  font-weight: 400;
  font-style: italic; }

@font-face {
  font-family: "Neris";
  src: url("/fonts/Neris-Light-webfont.woff") format("woff"), url("/fonts/Neris-Light-webfont.woff2") format("woff2");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: "Neris";
  src: url("/fonts/Neris-LightItalic-webfont.woff") format("woff"), url("/fonts/Neris-LightItalic-webfont.woff2") format("woff2");
  font-weight: 300;
  font-style: italic; }

@font-face {
  font-family: "Neris";
  src: url("/fonts/Neris-Black-webfont.woff") format("woff"), url("/fonts/Neris-Black-webfont.woff2") format("woff2");
  font-weight: 900;
  font-style: normal; }

@font-face {
  font-family: "Neris";
  src: url("/fonts/Neris-BlackItalic-webfont.woff") format("woff"), url("/fonts/Neris-BlackItalic-webfont.woff2") format("woff2");
  font-weight: 900;
  font-style: italic; }

.study-details-component {
  padding-top: 3.75rem; }

.summary {
  border-top-color: #ED6437 !important; }

.select-checkbox-cell {
  background-color: #1e2125; }

.patient-name {
  position: relative;
  z-index: 1; }

.series-row {
  transition: border-top-color 0.15s ease-out; }
  .series-row:hover, .series-row.chosen {
    border-top-color: #5358b5 !important; }

.clickable-row,
.clickable-row:hover {
  color: inherit;
  text-decoration: none; }

/*# sourceMappingURL=x.map */</style>
