<svelte:options immutable={true} />

<script>
  // Import our external dependencies.
  import { tick } from "svelte";
  import { translate, parseDate, formatDate } from "i18n"; //eslint-disable-line import/no-unresolved
  import { queryParameters, updateQueryParameters } from "../stores/router";
  import {
    addWildcardTag,
    parseDICOMwebJSON,
    sortByAcquisitionDesc,
  } from "../helpers/dicomweb";
  import { api, OrgQP, SelectedOrg } from "../helpers/api";
  import SearchBar from "../components/search-bar.svelte";
  import { showAlert } from "../stores/alerts";
  import noop from "lodash-es/noop";
  import user from "../stores/user";
  import OrgSelector from "../components/org-selector.svelte";
  import TagSelector from "../components/tag-selector.svelte";

  // Component Props & Constants.
  const perPage = 20;

  // Search
  // - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - - |
  let searchTxt = $queryParameters.search || "";
  let dicomTag = $queryParameters.dicomTag || "";
  async function handleSearchTxtChange({ detail: txt }) {
    abortHTTP();
    await tick();
    await tick();
    searchTxt = txt;
    studies = [];
    loadOffset = 0;
    updateQueryParameters({ search: txt || null });
  }

  async function handleDicomTagChange({ detail: txt }) {
    abortHTTP();
    await tick();
    await tick();
    dicomTag = txt;
    studies = [];
    loadOffset = 0;
    updateQueryParameters({ dicomTag: txt || null });
  }

  // Load Studies
  // - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - - |

  // Template-bound properties
  let studies = [];
  let httpInFlight = false;
  let loadOffset = 0;
  let canLoadMore = false;

  // Helper to cancel in-flight requests.
  let abortHTTP = noop;

  // Reactive! - Load studies whenever the search of pagination changes.
  $: loadStudies(searchTxt, loadOffset, $OrgQP, dicomTag);
  function loadStudies() {
    //
    // Format the DICOMweb request.
    // let studies_url = `/studies?${$OrgQP}&includefield=0020000D,00142018,0008002A,00081030&limit=${perPage}`; for when we need to revert
    let studies_url = `/quickload-studies?${$OrgQP}`;
    const urlParams = { limit: perPage, offset: loadOffset };

    if (dicomTag && searchTxt) {
      urlParams[dicomTag] = addWildcardTag(dicomTag, searchTxt);
    }

    studies_url +=
      ($OrgQP ? "&" : "") + new URLSearchParams(urlParams).toString();

    // Prepare our cancelation mechanism.
    let signal;
    if ("AbortController" in window) {
      const controller = new AbortController();
      signal = controller.signal;
      abortHTTP = () => controller.abort();
    }

    // Execute the request
    httpInFlight = true;
    api
      .get(studies_url, { signal })
      .then((new_studies) => {
        httpInFlight = false;

        studies = [...studies, ...parseDICOMwebJSON(new_studies)];
        studies.sort(sortByAcquisitionDesc);

        // enable the load more button if we received a full load.
        canLoadMore = new_studies.length >= perPage;
      })
      .catch((err) => {
        httpInFlight = false;
        if (err && err.name !== "AbortError") {
          showAlert({
            message: err,
          });
        }
      });
  }

  // Wipe loaded studies when the selected Organization changes.
  SelectedOrg.subscribe(() => {
    studies = [];
    loadOffset = 0;
  });
</script>

<section class="study-home-component" data-component="study-home">
  <main class="p-3">
    <h1 class="mb-3">{translate("modules.study", 99)}</h1>

    <!-- Search  & Filter -->
    <div class="container-fluid px-0">
      <div class="form-row">
        <div class="col-12 col-md-3 mb-3 mb-md-0">
          <TagSelector {dicomTag} on:dicomTagChange={handleDicomTagChange} />
        </div>
        <div class="col">
          <SearchBar
            {searchTxt}
            on:searchTxtChange={handleSearchTxtChange}
            helpCopy={translate("study_data.search_help")}
          />
        </div>

        <!-- Org Filter Selection -->
        {#if $user.app_metadata.is_hyperfine_admin}
          <div class="col-12 col-md-3 mt-3 mt-md-0">
            <OrgSelector />
          </div>
        {/if}
      </div>
    </div>

    <!-- List of Studies with Empty State -->
    {#if studies.length === 0 && !httpInFlight}
      <h3 class="text-center mt-5">{translate("study_data.none_found")}</h3>
    {:else}
      {#each studies as study}
        <a
          class="study-list-item | my-3 py-2"
          href="#/study-details/{study.StudyInstanceUID}"
        >
          <div class="container-fluid">
            <div class="form-row">
              <!-- Patient Info - Name, Date of Birth, & MRN -->
              <div class="col-12 col-sm-5 col-md-6 col-lg-5 position-unset">
                <div class="row">
                  <!-- Patient Name -->
                  <h5
                    class="col-12 col-md-6 text-truncate mb-1"
                    title={translate("patient_data.name")}
                  >
                    {study.PatientName}
                  </h5>

                  <!-- MRN (Medical Records Number) -->
                  <div class="col-md-6 pt-little mb-0 break-word">
                    <span class="small text-muted letter-space-1">
                      {translate("study_data.medical_records_number_short")}
                    </span>
                    <span class="small text-muted d-md-none">:</span>
                    <br class="d-none d-md-block" />
                    <span class="row-val">
                      {@html study.PatientID || "&nbsp;"}
                    </span>
                  </div>

                  <!-- Date of Birth -->
                  <div
                    class="col-12 col-md-6 text-truncate small text-muted
                    mt-md--4 pt-md-1"
                  >
                    <span class="letter-space-1">
                      {translate("patient_data.dob", 1)} :
                    </span>
                    <span>
                      {#if study.PatientBirthDate}
                        {formatDate(parseDate(study.PatientBirthDate), "M/d/y")}
                      {/if}
                    </span>
                  </div>

                  <!-- Badge -->
                  <div class="col-12 position-unset">
                    <span
                      class="badge badge-studies |  mt-2 mb-2 ml-3 ml-sm-0 mb-sm-0 position-absolute bottom-0 left-0"
                    >
                      {translate("modules.study", 1)}
                    </span>
                  </div>
                </div>
              </div>

              <!-- Study Info -->
              <div class="col-sm pb-4 pb-sm-0">
                <div class="row pb-2 pb-sm-0">
                  <!-- Summary Header (card-view only) -->
                  <div class="col-12 d-md-none">
                    <h6 class="summary-header mt-3 mt-sm-0">
                      {translate("study_data.summary")}
                    </h6>
                  </div>

                  <!-- Conducted At -->
                  <div
                    class="col-12 col-md-auto order-md-last text-truncate
                    pt-little pr-xl-5"
                  >
                    <span class="small text-muted letter-space-1">
                      {translate("study_data.conducted_at")}
                    </span>
                    <span class="small text-muted d-md-none">:</span>
                    <br class="d-none d-md-block" />
                    <span class="row-val">
                      {#if study.AcquisitionDateTime}
                        {formatDate(
                          parseDate(study.AcquisitionDateTime),
                          "M/d/y, h:mm b"
                        )}
                      {/if}
                    </span>
                  </div>

                  <!-- Indication -->
                  <div
                    class="col-12 col-md order-md-first break-word pt-little"
                  >
                    <span class="small text-muted letter-space-1">
                      {translate("study_data.indication_description")}
                    </span>
                    <span class="small text-muted d-md-none">:</span>
                    <br class="d-none d-md-block" />
                    <span class="row-val">
                      {@html study.IndicationDescription ||
                        study.StudyDescription ||
                        "&nbsp;"}
                    </span>
                  </div>
                </div>
              </div>

              <!-- Multiviewer Link -->
              <div
                class="col-12 col-sm-auto d-flex justify-content-center
                position-unset"
              >
                <a
                  title={translate("study_data.view_study")}
                  href="#/multiviewer?studies={study.StudyInstanceUID}"
                  class="view-study-btn btn btn-link pl-3 pt-3 pt-sm-0 pr-sm-1
                  position-absolute top-0 right-0 d-flex align-items-center"
                >
                  <i class="fas fa-eye" />
                </a>
              </div>
            </div>
          </div>
        </a>
      {/each}

      <!-- Load More Button & Loading Spinner -->
      <div class="d-flex justify-content-center mt-3">
        {#if canLoadMore}
          <button
            class="btn btn-outline-primary"
            disabled={httpInFlight}
            on:click={() => (loadOffset = studies.length)}
          >
            <span>{translate("form_labels.load_more")}</span>
          </button>
        {/if}
        {#if httpInFlight}
          <div class="animated fadeIn w-0">
            <div class="spinner-border small ml-3" role="status">
              <span class="sr-only">{translate("loadingDotDotDot")}</span>
            </div>
          </div>
        {/if}
      </div>
    {/if}
  </main>
</section>

<style type="text/scss">@font-face {
  font-family: "Neris";
  src: url("/fonts/Neris-Regular-webfont.woff") format("woff"), url("/fonts/Neris-Regular-webfont.woff2") format("woff2");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: "Neris";
  src: url("/fonts/Neris-Italic-webfont.woff") format("woff"), url("/fonts/Neris-Italic-webfont.woff2") format("woff2");
  font-weight: 400;
  font-style: italic; }

@font-face {
  font-family: "Neris";
  src: url("/fonts/Neris-Light-webfont.woff") format("woff"), url("/fonts/Neris-Light-webfont.woff2") format("woff2");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: "Neris";
  src: url("/fonts/Neris-LightItalic-webfont.woff") format("woff"), url("/fonts/Neris-LightItalic-webfont.woff2") format("woff2");
  font-weight: 300;
  font-style: italic; }

@font-face {
  font-family: "Neris";
  src: url("/fonts/Neris-Black-webfont.woff") format("woff"), url("/fonts/Neris-Black-webfont.woff2") format("woff2");
  font-weight: 900;
  font-style: normal; }

@font-face {
  font-family: "Neris";
  src: url("/fonts/Neris-BlackItalic-webfont.woff") format("woff"), url("/fonts/Neris-BlackItalic-webfont.woff2") format("woff2");
  font-weight: 900;
  font-style: italic; }

:global(.study-list-series-badge) {
  border: 1px solid #5358b5; }

.study-home-component {
  padding-top: 2.75rem; }

.study-list-item {
  position: relative;
  display: block;
  border: 1px solid #000;
  border-radius: 0.25rem;
  color: inherit;
  transition: border-top-color 0.15s ease-out; }
  .study-list-item:hover {
    color: inherit;
    text-decoration: none;
    border-top-color: #ED6437 !important; }

.row-val {
  font-size: 80%;
  color: #6c757d; }
  @media (min-width: 768px) {
    .row-val {
      font-size: inherit;
      color: inherit; } }

@media (min-width: 576px) {
  .open-all-btn {
    position: relative !important; } }

.pt-little {
  margin-bottom: -4px; }
  @media (min-width: 768px) {
    .pt-little {
      padding-top: 2px;
      margin-bottom: 0; } }

.summary-header {
  margin-bottom: 9px; }

@media (min-width: 576px) {
  .view-study-btn {
    position: unset !important; } }

@media (min-width: 576px) {
  .badge-studies {
    position: relative !important; } }

/*# sourceMappingURL=x.map */</style>
